/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [type="text"],
    [type="email"],
    [type="url"],
    [type="password"],
    [type="number"],
    [type="date"],
    [type="datetime-local"],
    [type="month"],
    [type="search"],
    [type="tel"],
    [type="time"],
    [type="week"],
    [type="file"],
    [multiple],
    textarea,
    select {
        @apply border-gray-300 rounded shadow-sm focus:border-primary-500 focus:ring-primary-500 focus:bg-purple-100;
    }

    [type="checkbox"] {
        @apply border-gray-300 rounded text-primary-500 focus:ring-primary-500;
    }

    [type="radio"] {
        @apply border-gray-300 text-primary-400 focus:ring-primary-500;
    }
}
